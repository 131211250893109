import {
  inputEmits,
  inputProps,
  type InputProps,
  type InputValueType,
} from '@base/components/ui/input'
import type { BasicTokenType, BulletType } from '@deorderbook/shared'
import type { ExtractPropTypes, ExtractPublicPropTypes } from 'vue'

export const tokenInputProps = {
  ...inputProps,
  /** input label */
  label: {
    type: String,
    default: 'Amount',
  },
  token: {
    type: String as PropType<BasicTokenType | BulletType>,
    required: true,
  },
  tokenContractAddress: {
    type: String,
    default: '',
  },
  /**
   * The prompt information for the input component
   * @notice Display only when the input component has a value
   */
  prompt: {
    type: String,
    default: undefined,
  },
  /**
   * Used for verifying input, when the return value is a string, an error message is displayed
   * @notice The component will default to verifying the balance of the token, and after executing props.validate, the default verification will be performed
   */
  validate: {
    type: Function as PropType<
      (value: InputValueType, tokenBalance: string) => string | undefined
    >,
    default: undefined,
  },
  placeholder: {
    type: String as PropType<InputProps['placeholder']>,
    default: '0',
  },
  /**
   * Max token amount to use instead of tokenBalance
   */
  maxAmount: {
    type: String,
    default: undefined,
  },
  /**
   * Using default checkBalance validation, set to false if you want to use custom validate function only
   * @default true
   */
  useDefaultValidation: {
    type: Boolean,
    default: true,
  },
} as const
export type TokenInputProps = ExtractPropTypes<typeof tokenInputProps>
export type TokenInputPublicProps = ExtractPublicPropTypes<
  typeof tokenInputProps
>

export const tokenInputEmits = inputEmits
export type TokenInputEmits = typeof tokenInputEmits
