import {
  inputEmits as elInputEmits,
  inputProps as elinputProps,
} from 'element-plus'
import type { ExtractPropTypes, ExtractPublicPropTypes } from 'vue'
import type { SIZE_ENUM } from './constants'

export type InputValueType = string | number
export type ValueAlignType = 'left' | 'right'
export type SizeType = EnumAsUnion<typeof SIZE_ENUM>

const { size, ...otherElInputProps } = elinputProps
export const inputProps = {
  ...otherElInputProps,
  /**
   * A verification function that receives the input value as a parameter.
   * If there is a return value, it is used as error text.
   */
  validate: {
    type: Function as PropType<(v: InputValueType) => string | undefined>,
    default: undefined,
  },
  /**
   * Alignment of input values
   * @default left
   */
  valueAlign: {
    type: String as PropType<ValueAlignType>,
    default: 'left',
  },
  /** display input border
   * * @default left
   */
  showBorder: {
    type: Boolean,
    default: true,
  },
  /**
   * input size, large is 60px,  default is 50px
   * @default default
   */
  size: {
    type: String as PropType<SizeType>,
    default: 'default',
  },
  /**
   * whether to show clear button
   * @notice can't use with suffix slot
   * @default false
   */
  clearable: {
    type: Boolean,
    default: false,
  },
}
export type InputProps = ExtractPropTypes<typeof inputProps>
export type InputPublicProps = ExtractPublicPropTypes<typeof inputProps>
export const inputEmits = {
  ...elInputEmits,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  enter: (value: InputValueType) => undefined,
} as const
export type InputEmits = typeof inputEmits
